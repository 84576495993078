<template>
    <el-table
        border
        :data="tableData"
        tooltip-effect="dark"
        empty-text="请上传文件"
        header-cell-class-name="meterHeader"
        >
        <slot name="tableColumn">
            <el-table-column prop="fileName" label="文件名称" align="center"  />
        </slot>
        <el-table-column align="center" width="100">
            <template slot="header" slot-scope="scope">
                <Upload :accept="accept" :fields="fields" :multiple="true" @upload="upload"></Upload>
            </template>
            <template slot-scope="scope">
                <slot name="operation" :scope="scope">
                    <i class="iconfont iconerror" @click="del(scope.$index)"></i>
                </slot>
            </template>

        </el-table-column>
          <!-- <el-table-column
            width="60px"
            align="center">
            <template slot="header" slot-scope="scope">
                <i class="iconfont iconadd-circle" @click="addRow"></i>
            </template>
            <template slot-scope="scope">
                <i class="iconfont iconerror" @click="del(scope.$index)"></i>
            </template>
          </el-table-column> -->
    </el-table>
</template>

<script>

export default {
    components: {
        Upload: () => import("./Upload.vue"),
        UploadImages: () => import("@/components/upload/Images.vue"),
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Array,
            default: function (){
                return []
            }
        },
        accept:{
            type:String,
            default:'image/*',
        },
        fields:{
            type: Object,
            default:function(){
                return {
                    fileName:'fileName',
                    fileAddress:'fileAddress'
                }
            }
        },
    },
    data() {
        return {
            tableData:[],
        }
    },
    watch: {
        value:{
            immediate:true,//初始化立即执行
            handler: function (newVal){
                this.tableData = newVal||[];
            }
        },
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    mounted() {

    },
    methods: {
        upload(r){
            r.some(res=>{
                this.tableData.push(res);
            });
            this.$emit('change', this.tableData);
        },
        del(index){
            this.tableData.splice(index, 1);
        },
        addRow () {
            // 创建一个新的行数据对象
            const newRow = {
                fileName: '', // 根据实际需求填充其他属性
            };

            // 将新的行数据对象添加到表格数据源中
            this.tableData.push(newRow);
        },
    }
};
</script>

<style lang="scss" scoped>

</style>
